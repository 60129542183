import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our Features
                    </span>

                    <h2>AbInitio Partners</h2>
                    <p>We are proud about our Strategic Partnership with AbInitio, a Data Platform that proved effective over multiple complex implementations.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Governance</h3>
                            <p>Data is a critical Business Asset and with Metadata Hub we helped our clients to describe the data that flows into their organization, what it contains, who's responsible, what quality it has, and it relates to each other as well as answering to regulatory like BCBS239.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Data Processing</h3>
                            <p>AbInitio supports entire life cycle of a Data Processing platform through digitally enabling applications with a high level of collaboration across the enterprise. Starting from business logic, through rule-based&graphical development, until testing the results, the platform empowers business knowledge and facilitates efficient technical detailed processing.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>BI, Analytics & Data Engineering</h3>
                            <p>We can support through the Platform finding and preparing the data for data scientists, successful model trainings, enabling model executions and deployment, all that with data cataloging & virtualization, simplified promotion of machine learning models from development to deployment and batch/real-time development to production environments.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Semantic Discovery & Testing</h3>
                            <p>With a search, scoring and matching technology, our clients were able to discover and understand their data, along complying with regulatory requests like GDPR. Delivering full testing environments keeping business logic from "real life", but covering and hiding sensitive information, is important to ensure a safe development and testing environment.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Data Quality</h3>
                            <p>No matter the industry of our clients, a good data quality ensures progress and ensures best decisions upon it. With AbInitio, simple and complex frameworks can be achieved to provide quality measurements and make use of those.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>Architecture</h3>
                            <p>AbInitio's strong architecture leads to a strong foundation and having all topics integrated into one single platform it empowers and supports: building (big) systems faster, having them efficient and scalable lowering the operating costs and having those systems more resilient which is important for mission-critical ones.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures