import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>We value the data and help you understand and gain from it</h2>
                    <p>Our main goal is to empower clients with means to understand the data and put that knowledge to work for their benefits by using cutting-age technologies and scalable architecture setups.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details-discovery">
                                    Discovery
                                </Link>
                            </h3>
                            <p>Most of the data scientist's day is not spent training the machine learning models, but finding and preparing the data. We help to organize the data in this process with best practices and tools.</p>

                            <Link to="/service-details-discovery" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/service-details-processing">
                                    Processing
                                </Link>
                            </h3>

                            <p>Starting with Analysis, Mapping Specifications, ETL Development, Orchestration and Scheduling, the entire ETL Process is covered for effectiveness. We consider cost, performance and scalability.</p>
                            
                            <Link to="/service-details-processing" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details-science">
                                    Science & Analytics
                                </Link>
                            </h3> 

                            <p>We support the entire process of data science & analytics from data identification, cleansing, model creations, until training and deployment.</p>
                            
                            <Link to="/service-details-science" className="view-details-btn">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;